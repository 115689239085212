<template>
  <div class="b-card-products b-card_products-dense">
    <div class="b-card_products-dense__content--btn">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            class="b-card-products__btn"
            small
            @click="downloadDocument()"
          >
            <v-icon class="b-card-products__btn--icon">
              fa-solid fa-download
            </v-icon>
          </v-btn>
        </template>
        <span>Baixar arquivo</span>
      </v-tooltip>

      <v-tooltip bottom v-if="cannotEdit">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            class="b-card-products__btn"
            small
            @click="uploadDocument()"
          >
            <v-icon class="b-card-products__btn--icon">
              fa-solid fa-upload
            </v-icon>
            <input
              ref="input"
              type="file"
              class="d-none"
              accept=".xlsx,.xls,.csv"
              @change="handleFile($event)"
            />
          </v-btn>
        </template>
        <span>Enviar arquivo</span>
      </v-tooltip>
    </div>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <h3 v-on="on" class="b-card-products__title">
          {{ doTruncateText(document.fileName, 25) }}
        </h3>
      </template>
      <span>{{ document.fileName }}</span>
    </v-tooltip>

    <span class="b-card-products__subtitle">{{ description }}</span>
  </div>
</template>

<script>
import { doTruncateText } from '@/helpers/utils';

export default {
  props: {
    document: {
      type: Object,
      require: true
    },
    item: {
      type: Object,
      require: true
    },
    cannotEdit: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    description() {
      if (this.cannotEdit) {
        return 'Clique nos ícones para realizar o download e upload do arquivo';
      } else {
        return 'Clique no ícone para realizar o download do arquivo';
      }
    }
  },

  methods: {
    doTruncateText,

    downloadDocument() {
      this.$emit('downloadDocument', this.document);
    },

    uploadDocument() {
      this.$refs.input.value = null;
      this.$refs.input.click();
    },

    handleFile({ target }) {
      let document = Object.assign({}, this.document);
      document.file = target.files[0];
      document.contractId = this.item.contractId;

      this.$emit('uploadDocument', document);
    }
  }
};
</script>
